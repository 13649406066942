'use client';

import { ChevronDown } from 'lucide-react';
import { memo, useCallback, useMemo, useState } from 'react';
import { cn } from '../../utils/cn';
import { TableCell } from './table-cell';
import { TableRowExpander } from './table-row-expander';
import { TableCellOptions, TableProps, TableRowOptions } from './types';

export type TableRowProps<T> = Pick<
  TableProps<T>,
  | 'columns'
  | 'compact'
  | 'className'
  | 'cellClassName'
  | 'stickyLeftClassName'
  | 'expandable'
  | 'expandableRow'
  | 'expandableData'
  | 'expandableDataRemoteOptions'
  | 'expandableComponent'
  | 'expandedRowClassName'
  | 'rowKey'
  | 'onRowMouseEnter'
  | 'onRowMouseLeave'
  | 'onRowClick'
> & {
  rowData: T;
  rowIndex: number;
  rowSpans: number[];
  cellVisibilities: boolean[];
  isExpandedRow?: boolean;
};

function _TableRow<T>(props: TableRowProps<T>) {
  const {
    rowKey,
    columns,
    compact,
    rowData,
    rowIndex,
    rowSpans,
    cellVisibilities,
    className,
    cellClassName,
    stickyLeftClassName,
    expandable,
    expandableRow,
    isExpandedRow,
    onRowMouseEnter: onMouseEnter,
    onRowMouseLeave: onMouseLeave,
    onRowClick: onClick,
  } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const isExpandableRow = expandable ? expandableRow?.({ rowData, rowIndex }) ?? true : false;
  const rowOptions = useMemo<TableRowOptions<T>>(
    () => ({
      rowData,
      rowIndex,
    }),
    [rowData, rowIndex]
  );
  const handleMouseEnter = useCallback(() => {
    onMouseEnter?.(rowOptions);
  }, [rowOptions]);
  const handleMouseLeave = useCallback(() => {
    onMouseLeave?.(rowOptions);
  }, [rowOptions]);
  const handleClick = useCallback(() => {
    if (isExpandableRow) {
      setExpanded((prev) => !prev);
    }
    onClick?.(rowOptions);
  }, [rowOptions, isExpandableRow]);

  return (
    <>
      <tr
        className={cn(
          {
            'cursor-pointer': isExpandableRow,
          },
          className
        )}
        data-key={rowKey?.(rowOptions)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {expandable ? (
          <td className="align-middle" width="30">
            <div className="w-8 md:w-12 lg:w-15 flex justify-center">
              <ChevronDown
                size={24}
                className={cn('text-gray-700 transition-transform duration-300 ease-out', {
                  '-rotate-90': !expanded,
                })}
              />
            </div>
          </td>
        ) : isExpandedRow ? (
          <td width="1%" className="bg-gray-300" />
        ) : null}
        {columns.map((column, columnIndex) => {
          const rowSpan = rowSpans[columnIndex];
          const options: TableCellOptions<T> = {
            column,
            columnIndex,
            rowData,
            rowIndex,
            rowSpan,
          };
          const isHidden = !cellVisibilities[columnIndex];

          return isHidden ? null : (
            <TableCell
              key={(column.key as string) ?? columnIndex}
              className={
                typeof cellClassName === 'function' ? cellClassName(options) : cellClassName
              }
              column={column}
              columnIndex={columnIndex}
              compact={compact}
              isFirstColumn={columnIndex === 0}
              isLastColumn={columnIndex === columns.length - 1}
              rowData={rowData}
              rowIndex={rowIndex}
              rowSpan={rowSpan}
              stickyLeftClassName={stickyLeftClassName}
            />
          );
        })}
      </tr>
      {isExpandableRow && <TableRowExpander<T> {...props} expanded={expanded} />}
    </>
  );
}

export const TableRow = memo(_TableRow) as typeof _TableRow;
