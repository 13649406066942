'use client';

import { useRemote, useSize } from '@spikemark/shared-hooks';
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { cn } from '../../utils/cn';
import { LoadingSpinner } from '../loading-spinner';
import { useTableContext } from './table-provider';
import { TableRowProps } from './table-row';
import { TableRowGroup } from './table-row-group';
import { TableRowOptions } from './types';
import { useWindowSize } from '@uidotdev/usehooks';

type TableRowExpandableComponentContainerProps<T> = Required<
  Pick<TableRowExpanderProps<T>, 'expandableComponent'>
> &
  TableRowOptions<T> & {
    expanded: boolean;
    isLoading: boolean;
    expandedData: T[] | null;
  };

function _TableRowExpandableComponentContainer<T>({
  expanded,
  expandedData,
  isLoading,
  rowData,
  rowIndex,
  expandableComponent: Component,
}: TableRowExpandableComponentContainerProps<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const size = useSize(ref);
  const [width, setWidth] = useState<string | number>(0);
  const { ref: tableRef } = useTableContext();
  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    // When the window is resized, we need to disregard the width of the expanded container
    // when we are calculating the new width of the table. This involves us "hiding it" by
    // setting the width to 0, then on the next frame we take a measurement of the table
    // and set the size of the expandable component. This creates a flicker on resize but
    // it's a difficult issue to get around without this flicker.
    setWidth(0);

    requestAnimationFrame(() => {
      if (tableRef.current) {
        const nextWidth = Math.floor(tableRef.current.clientWidth) - 1;
        setWidth(nextWidth);
      }
    });
  }, [windowSize.width]);

  return (
    <div
      ref={containerRef}
      className={cn('duration-500 ease-expo-out overflow-hidden w-full max-w-screen', {
        'opacity-60': !expanded,
      })}
      style={{
        transitionProperty: 'opacity, height',
        width,
        height: expanded ? (isLoading || !size.height ? 40 : size.height) : 0,
      }}
    >
      {isLoading && <LoadingSpinner />}
      <div
        ref={ref}
        className={cn('transition-all duration-500 ease-expo-out w-full max-w-screen', {
          'opacity-0 -translate-y-10 pointer-events-none': !expanded,
        })}
      >
        <Component data={expandedData} rowData={rowData} rowIndex={rowIndex} />
      </div>
    </div>
  );
}

const TableRowExpandableComponentContainer = memo(
  _TableRowExpandableComponentContainer
) as typeof _TableRowExpandableComponentContainer;

type TableRowExpanderProps<T> = TableRowProps<T> & {
  expanded: boolean;
};

function _TableRowExpander<T>(props: TableRowExpanderProps<T>) {
  const {
    columns,
    expanded,
    expandable,
    expandableData,
    expandableDataRemoteOptions,
    expandedRowClassName,
    expandableComponent,
    rowData,
    rowIndex,
    ...rowGroupProps
  } = props;
  const {
    data: expandedData,
    error,
    isLoading,
  } = useRemote<T[], TableRowOptions<T>>(expandableData, {
    ...(expandableDataRemoteOptions ?? {}),
    params: {
      ...expandableDataRemoteOptions?.params,
      rowData,
      rowIndex,
    },
    reset: expandableDataRemoteOptions?.reset || false,
    skip: expandableDataRemoteOptions?.skip || !expanded || !expandableData,
  });
  const numColumns = columns.filter((x) => !x.hidden).length + 1;

  if (expandableComponent) {
    return (
      <tr className={expandedRowClassName}>
        <td colSpan={numColumns}>
          <TableRowExpandableComponentContainer
            expanded={expanded}
            expandedData={expandedData}
            isLoading={isLoading}
            rowData={rowData}
            rowIndex={rowIndex}
            expandableComponent={expandableComponent}
          />
        </td>
      </tr>
    );
  }

  if (expanded) {
    if (isLoading || error) {
      return (
        <tr className={expandedRowClassName}>
          <td />
          <td colSpan={columns.length}>
            {error ? `There was an issue loading this data: ${error.message}` : <LoadingSpinner />}
          </td>
        </tr>
      );
    }

    return (
      <TableRowGroup<T>
        columns={columns}
        data={expandedData}
        isExpandedRowGroup
        rowClassName={expandedRowClassName}
        {...rowGroupProps}
      />
    );
  }

  return null;
}

export const TableRowExpander = memo(_TableRowExpander) as typeof _TableRowExpander;
