'use client';

import { FC, memo } from 'react';
import { TableProps } from './types';

type TableEmptyRowProps = {
  className?: TableProps<any>['emptyRowClassName'];
  emptyMessage?: TableProps<any>['emptyMessage'];
  colSpan?: number;
  expandable?: boolean;
};

export const TableEmptyRow: FC<TableEmptyRowProps> = memo(
  ({ className, colSpan, emptyMessage, expandable }) => (
    <tr className={className}>
      {expandable && <td />}
      <td className="text-center pointer-events-none h-8" colSpan={colSpan}>
        {emptyMessage}
      </td>
    </tr>
  )
);
