'use client';

import classNames from 'classnames';
import { ChevronUp } from 'lucide-react';
import { memo, useCallback } from 'react';
import { useTableContext } from './table-provider';
import { TableColumnOptions, TableProps } from './types';
import { getHeaderCellClassName } from './util';

export type TableHeaderCellProps<T> = TableColumnOptions<T> &
  Pick<TableProps<T>, 'className' | 'headerCellClassName' | 'stickyLeftClassName' | 'compact'> & {
    isFirstColumn?: boolean;
    isLastColumn?: boolean;
  };

function _TableHeaderCell<T>(props: TableHeaderCellProps<T>) {
  const { column } = props;
  const { sort, toggleSort } = useTableContext<T>();
  const isSorted = sort?.column === column;
  const handleToggleSort = useCallback(() => {
    toggleSort(column);
  }, [toggleSort, column]);

  return (
    <th
      className={classNames(getHeaderCellClassName(props, 'header'), {
        'bg-gray-300 font-bold': isSorted,
      })}
      colSpan={column.headerColSpan}
      onClick={column.sortable ? handleToggleSort : undefined}
    >
      <div className="inline-flex items-center relative overflow-clip">
        {column.header && <div>{column.header}</div>}
        {column.sortable && (
          <div
            className={classNames('transition-opacity duration-250 mt-1', {
              'absolute -right-4 -top-0.5': !!column.header,
              'opacity-1': isSorted,
              'opacity-0 group-hover:opacity-50': !isSorted,
            })}
          >
            <ChevronUp
              size={16}
              className="transition-all duration-250"
              style={{ rotate: sort?.direction === 'desc' ? '180deg' : '' }}
            />
          </div>
        )}
      </div>
    </th>
  );
}

export const TableHeaderCell = memo(_TableHeaderCell) as typeof _TableHeaderCell;
